import React from "react";
import Banner from "@ingka/banner";
import warningIcon from "@ingka/ssr-icon/paths/warning-triangle";
import { t } from "i18next";
import { SKAPA_PREFIX } from "@/lib/constants";
import useConfig from "@/hooks/useConfig";
import useProfile from "@/hooks/useProfile";
import DateTime from "@ingka-group-digital/date-time";

const MaintenanceBanner = () => {
    const profile = useProfile();

    const globalStatus = useConfig<any>("maintenance");
    const countryStatus = useConfig<any>(`maintenance.${profile?.unit.country}`);

    const status = countryStatus ? countryStatus : globalStatus;

    if (!status) {
        return;
    }

    return (
        <div data-test="maintenance-banner">
            <Banner
                prefix={SKAPA_PREFIX}
                body={countryStatus ? t(`maintenance.bannerMessage.${profile?.unit.country}`) : t(`maintenance.bannerMessage.global`)}
                emergency
                ssrIcon={warningIcon}
            />
        </div>
    );
};

export default MaintenanceBanner;
