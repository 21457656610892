import env from "@/env";

type CookieOpts = {
    name: string;
    // ommit maxAge for session cookie
    maxAge?: number; //seconds
    path?: string;
    domain?: string;
};

const DEFAULTS: CookieOpts = {
    name: "mia",
    domain: env.is("local") ? undefined : window.location.hostname.split(".").slice(1).join("."),
    maxAge: 60 * 60 * 24 * 365,
    path: "/",
};

export default class {
    private data: Record<string, any> = {};
    private opt: CookieOpts = DEFAULTS;

    constructor(opt: CookieOpts) {
        this.opt = { ...DEFAULTS, ...opt };
        const cookies = document.cookie.split("; ");
        for (const cookie of cookies) {
            const [n, value] = cookie.split("=");
            if (n !== this.opt.name) {
                continue;
            }

            try {
                this.data = JSON.parse(decodeURIComponent(value));
                log(`cookieJar:${this.opt.name}`, this.data);
            } catch (e) {
                log.error(`invalid ${this.opt.name} cookie value`);
            }

            break;
        }
    }

    set(key: string, value: any) {
        this.data[key] = value;
        this.exportCookie();
    }

    get(key: string, defaultValue?: any) {
        return this.data[key] ?? defaultValue;
    }

    delete(key: string) {
        if (key in this.data) {
            delete this.data[key];
            this.exportCookie();
        }
    }

    deleteAll() {
        document.cookie = `${this.opt.name}=; max-age=-1`;
        this.data = {};
    }

    private exportCookie() {
        const { name = "opp", maxAge, path, domain } = this.opt;

        let cookie = name + "=" + encodeURIComponent(JSON.stringify(this.data));

        if (maxAge) {
            cookie += `; max-age=${maxAge}`;
        }

        if (path) {
            cookie += `; path=${path}`;
        }

        if (domain) {
            cookie += `; domain=${domain}`;
        }

        document.cookie = cookie;
    }
}
