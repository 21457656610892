import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useCommonStore from "@/store/hooks/useCommonStore";
import Button from "@ingka/button";
import Switch from "@ingka/switch";
import { Permission } from "@/types/profile";
import Loader from "@/components/Loaders/Loaders";
import LanguageSelect from "@/components/LanguageSelect";
import { Link } from "react-router-dom";
import { useApplicationUrl } from "@/hooks/useApplicationUrl";
import personIcon from "@ingka/ssr-icon/paths/person";
import globeIcon from "@ingka/ssr-icon/paths/globe";
import shieldIcon from "@ingka/ssr-icon/paths/shield-checkmark";
import certIcon from "@ingka/ssr-icon/paths/certificate";
import briefcaseIcon from "@ingka/ssr-icon/paths/briefcase";
import ListView, { ListViewItem } from "@ingka/list-view";
import { storage } from "@/api/storage";
import CookieJar from "@/lib/cookieJar";
import "./Profile.scss";
import { auth } from "@/api/auth";
import { MQ_MOBILE_BREAKPOINT, PRIVACY_POLICY_PAGE, SKAPA_PREFIX } from "@/lib/constants";
import ProfilePicture from "./ProfilePicture";
import useProfile from "@/hooks/useProfile";
import PrivacyPolicySelect from "../Consent/PrivacyPolicySelect";
import { User, Role } from "@packages/common/user";
import { TRANSLATION_LANGUAGES } from "@packages/common/etc";
import { useModals } from "../Modals/ModalStack";
import useMediaQuery from "@/hooks/useMediaQuery";
import feedbackIcon from "@ingka/ssr-icon/paths/feedback-happy";
import Feedback from "../Feedback/Feedback";
import DateTime from "@ingka-group-digital/date-time";
import useServerRequest from "@/hooks/useServerRequest";
import { DATE_FORMAT } from "@packages/common/calendar";
import { ApprovalStatus, WorkPreference } from "@packages/common/availability";
import useConfig from "@/hooks/useConfig";
import parasolIcon from "@ingka/ssr-icon/paths/parasol";

export const getPrivacyPolicy = (profile: User) => {
    if (!profile?.unit.country || !profile?.language) {
        return PRIVACY_POLICY_PAGE;
    } else {
        return `https://go.ikea.com/mia-privacy-${profile.unit.country}-${profile.language}`;
    }
};

const CoworkerProfile = () => {
    const { t } = useTranslation();
    const profile = useProfile();
    const { openSheet, closeAllModals } = useModals();
    const isDevToolsVisible = useCommonStore(({ isDevToolsVisible }) => isDevToolsVisible);
    const setDevToolsVisible = useCommonStore(({ setDevToolsVisible }) => setDevToolsVisible);
    const switchUrl = useApplicationUrl("switch");
    const cookieJar = new CookieJar({ name: "opp" });
    const isMobile = useMediaQuery((w) => w < MQ_MOBILE_BREAKPOINT);

    const showLeaveBalancesInProfile = useConfig<boolean>("showLeaveBalancesInProfile", false);
    const vacationAvailabilityRequest = useConfig<ApprovalStatus[]>("vacationAvailabilityRequest", [ApprovalStatus.APPROVED]);

    useEffect(() => {
        storage.get<boolean>("showDevTools", true).then(setDevToolsVisible);
    }, []);

    const handleLanguageMenu = () => {
        openSheet(LanguageSelect, {
            "data-analytics": "cw-profile-language",
            unstyled: false,
            header: "",
        });
    };

    const handlePrivacyPolicyMenu = () => {
        openSheet(PrivacyPolicySelect, {
            "data-analytics": "cw-profile-privacy",
            header: t("profile.privacyPolicy"),
            unstyled: true,
        });
    };

    const handleFeedback = () => {
        openSheet(Feedback, {
            header: t("feedback.title"),
            unstyled: true,
        });
    };

    const langCode = cookieJar.get("lang", profile?.language ?? "en-US") as keyof typeof TRANSLATION_LANGUAGES;
    const languageLabel = langCode in TRANSLATION_LANGUAGES ? TRANSLATION_LANGUAGES[langCode] : TRANSLATION_LANGUAGES["en-US"];

    const activityList = profile?.activities?.map(({ name }) => name).join(", ");

    if (!profile) {
        return <Loader type="ball" />;
    }

    let leaveBalance: number = -1;
    if (showLeaveBalancesInProfile) {
        const { isLoading: loadingLeaveBalance, data: data } = useServerRequest("availability/count", {
            fromDate: DateTime.today().startOfYear().format(DATE_FORMAT),
            toDate: DateTime.today().endOfYear().format(DATE_FORMAT),
            workPreference: [WorkPreference.VACATION],
            status: vacationAvailabilityRequest,
            coworkerId: profile.id,
            timeUnit: "day",
        });
  
        leaveBalance = data?.length ? data[0].count : -1;
    }

    return (
        <div className="profile-menu" data-monitor="profile-menu" data-test="profile-wrapper">
            <div className="header">
                <ProfilePicture size={96} mail={profile.email} />
                <h3>{profile.name}</h3>
                <p>{profile.jobTitle ?? t(`user.role.${profile.role}`)}</p>
                <p>{profile.unit?.name}</p>
                <div className="profile-actions">
                    <Button
                        type="secondary"
                        small
                        className="logout-btn"
                        text={t("profile.logOut")}
                        onClick={() => auth.logout()}
                        prefix={SKAPA_PREFIX}
                        fluid
                        data-analytics="cw-profile-logout"
                        data-test="cw-profile-logout"
                    />
                    {profile.has(Permission.SWITCH_APP) && (
                        <Link to={switchUrl} onClick={() => closeAllModals()} className="switch-app-container">
                            <Button
                                type="primary"
                                small
                                className="switch-app"
                                data-monitor="switch-app-button"
                                data-test="switch-app-button"
                                text={t("profile.switchApp")}
                                prefix={SKAPA_PREFIX}
                                fluid
                            />
                        </Link>
                    )}
                    {(profile.role === Role.DEV || !!profile.developer) && (
                        <Switch
                            id="show-devtools-switch"
                            label={"Show DevTools"}
                            className="show-devtools"
                            prefix={SKAPA_PREFIX}
                            defaultChecked={isDevToolsVisible}
                            value="show-devtools"
                            onChange={(evt) => {
                                storage.set("showDevTools", evt.target.checked);
                                setDevToolsVisible(evt.target.checked);
                            }}
                        />
                    )}
                </div>
            </div>
            <ListView prefix={SKAPA_PREFIX} id="profile-info" className="list-section">
                <ListViewItem
                    prefix={SKAPA_PREFIX}
                    leadingIcon={briefcaseIcon}
                    title={t("division")}
                    description={profile.division?.name}
                    emphasised
                />

                {profile.contract?.hours ? (
                    <ListViewItem
                        prefix={SKAPA_PREFIX}
                        leadingIcon={personIcon}
                        title={t("profile.contract")}
                        description={`${profile.contract.hours} ${t("profile.hr")} / ${t("profile.week")}`}
                        emphasised
                    />
                ) : (
                    <></>
                )}

                {profile.role === Role.COWORKER || profile.role === Role.SHOPKEEPER || profile.role === Role.DEV ? (
                    <ListViewItem
                        prefix={SKAPA_PREFIX}
                        href={getPrivacyPolicy(profile)}
                        leadingIcon={certIcon}
                        title={t("activities")}
                        description={activityList}
                        emphasised
                    />
                ) : (
                    <></>
                )}

                {showLeaveBalancesInProfile ? (
                    <ListViewItem
                        prefix={SKAPA_PREFIX}
                        leadingIcon={parasolIcon}
                        title={t("profile.yourLeaveBalances")}
                        description={leaveBalance >= 0 ? `${leaveBalance} ${t("availConditions.metrics.events").toLocaleLowerCase()}` : "-"}
                        emphasised
                    />
                ) : (
                    <></>
                )}
            </ListView>
            <ListView prefix={SKAPA_PREFIX} id="profile-settings" className="list-section">
                <ListViewItem
                    prefix={SKAPA_PREFIX}
                    onClick={handleLanguageMenu}
                    leadingIcon={globeIcon}
                    control="navigational"
                    controlIcon="chevronRight"
                    title={t("preferredLanguage")}
                    description={languageLabel}
                    emphasised
                />
                <ListViewItem
                    prefix={SKAPA_PREFIX}
                    href={getPrivacyPolicy(profile)}
                    leadingIcon={shieldIcon}
                    newWindow="_blank"
                    title={t("profile.privacyPolicy")}
                    onClick={handlePrivacyPolicyMenu}
                    control="navigational"
                    controlIcon="chevronRight"
                    emphasised
                />
                {isMobile ? (
                    <ListViewItem
                        prefix={SKAPA_PREFIX}
                        leadingIcon={feedbackIcon}
                        newWindow="_blank"
                        title={t("feedback.button")}
                        onClick={handleFeedback}
                        control="navigational"
                        controlIcon="chevronRight"
                        emphasised
                    />
                ) : (
                    <></>
                )}
            </ListView>
        </div>
    );
};

export default CoworkerProfile;
