import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { SKAPA_PREFIX } from "@/lib/constants";
import { PRIVACY_STATEMENT_GLOBAL } from "@/lib/constants";

import Loader from "@/components/Loaders/Loaders";

import useProfile from "@/hooks/useProfile";

import Modal, { ModalBody, ModalFooter, ModalHeader, Prompt } from "@ingka/modal";
import Button from "@ingka/button";

import "./Consent.scss";

type Props = {
    onWithdraw: () => void;
    onClose: () => void;
};

const HaveConsent = ({ onWithdraw, onClose }: Props) => {
    const profile = useProfile();
    const { t } = useTranslation();
    const [visible, setVisible] = useState<boolean>(true);

    if (!profile) {
        return <Loader />;
    }

    return (
        <Modal
            escapable
            className="have-consent-popup"
            visible={visible}
            prefix={SKAPA_PREFIX}
            handleCloseBtn={onClose}
            data-test="have-consent-prompt"
        >
            <Prompt
                prefix={SKAPA_PREFIX}
                title={t("consent.title")}
                titleId="have-consent-modal"
                className="have-consent-prompt"
                header={null}
                footer={
                    <ModalFooter>
                        <Button
                            prefix={SKAPA_PREFIX}
                            text={t("consent.withdrawConsent")}
                            type={"secondary"}
                            onClick={() => {
                                setVisible(false);
                                onWithdraw();
                            }}
                        />
                    </ModalFooter>
                }
            >
                <ModalBody>
                    <div className="consent-body">
                        {t<string>("consent.bodyText")}
                        &nbsp;
                        <a className="consent-modal-privacy-statement-link" target="_blank" href={PRIVACY_STATEMENT_GLOBAL} rel="noreferrer">
                            {t("consent.privacyStatementLinkText")}
                        </a>
                    </div>

                    <div className="consent-body mt-3">
                        <p>{t("consent.manageConsentBody")}</p>
                    </div>
                </ModalBody>
            </Prompt>
        </Modal>
    );
};

export default HaveConsent;
