import React from "react";
import { SKAPA_PREFIX } from "@/lib/constants";
import Loading, { LoadingBall as SkapaBall } from "@ingka/loading";
import "./Loaders.scss";
import Skeleton from "@ingka/skeleton";

type Options = {
    when?: boolean;
    type?: "ball" | "line";
};

const Loader = (options: Options) => {
    if (!options.when) {
        return <></>;
    }
    switch (options.type) {
        case "ball": {
            return (
                <Loading prefix={SKAPA_PREFIX}>
                    <SkapaBall />
                </Loading>
            );
        }
        case "line": {
            return <div className="loader-line"></div>;
        }
        default: {
            return (
                <div className="full-height-loading">
                    <div className="loading-skeleton screen-md-only flex">
                        <nav>People availability</nav>
                        <Skeleton prefix={SKAPA_PREFIX} width="100%" height="100%" />
                    </div>
                    <div className="loading-ball screen-s-only">
                        <Loading>
                            <SkapaBall />
                        </Loading>
                    </div>
                </div>
            );
        }
    }
};

export default Loader;
