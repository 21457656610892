import { useEffect, useState } from "react";
import { storage } from "@/api/storage";

type SurveyData = { ignore: boolean; timestamp?: number };

/**
 * Handle survey.
 * @param surveyId windows form survey id
 * @param dismissalDurationMs set the time in ms untile the prompt will show again
 */

const useSurveyPrompt = (surveyDate: string | null, surveyId: string | null, dismissalDurationMs: number) => {
    const [showPrompt, setShowPrompt] = useState(false);
    const storageSurveyKey = "survey_" + surveyDate;

    useEffect(() => {
        (async () => {
            const surveyData = await storage.get<SurveyData>(storageSurveyKey);
            const now = new Date().getTime();

            if (surveyId && (!surveyData || (surveyData.timestamp && now - surveyData.timestamp >= dismissalDurationMs))) {
                setShowPrompt(true);
            }
        })();
    }, [surveyDate, surveyId, dismissalDurationMs]);

    // dismiss the survey prompt for the specified duration
    const dismissTemporarily = async () => {
        if (surveyId) {
            await storage.set(storageSurveyKey, { ignore: false, timestamp: new Date().getTime() });
            setShowPrompt(false);
        }
    };

    // dismiss and ignore current survey. Next survey will show
    const ignorePermanently = async () => {
        if (surveyId) {
            await storage.set(storageSurveyKey, { ignore: true, timestamp: null });
            setShowPrompt(false);
        }
    };

    return { showPrompt, dismissTemporarily, ignorePermanently };
};

export default useSurveyPrompt;
