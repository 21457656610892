import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./Feedback.scss";
import Button from "@ingka/button";
import { FEEDBACK_CATEGORIES, SKAPA_PREFIX } from "@/lib/constants";
import Select, { Option } from "@ingka/select";
import Checkbox from "@ingka/checkbox";
import FormField from "@ingka/form-field";
import TextArea from "@ingka/text-area";
import SSRIcon from "@ingka/ssr-icon";
import checkmarkCircle from "@ingka/ssr-icon/paths/checkmark-circle";
import crossCircle from "@ingka/ssr-icon/paths/cross-circle";
import { endpoints } from "@/api/http";

const Feedback = () => {
    const [isAnonymous, setIsAnonymous] = useState<boolean>(true);
    const [message, setMessage] = useState<string>("");
    const [category, setCategory] = useState<string>("Not specified");
    const [messageSent, setMessageSent] = useState<boolean>(false);
    const [messageSentError, setMessageSentError] = useState<boolean>(false);
    const FEEDBACK_MESSAGE_MAX_LENGTH = 500;
    const [busy, setBusy] = useState(false);

    const { t } = useTranslation();

    const onSubmitFeedback = async () => {
        try {
            const res = await endpoints.postFeedback({ anonymous: isAnonymous, message: message.trim(), category });
            log.info("Successfully sent feedback: ", res);
            setMessageSent(true);
        } catch (err) {
            setMessageSentError(true);
            log.info("Failed to send feedback: ", err);
        }
        setBusy(false);
    };

    if (messageSentError) {
        return (
            <div className="message-sent">
                <SSRIcon prefix={SKAPA_PREFIX} paths={crossCircle} color="#E00751" />
                <h3>{t("feedback.error")}</h3>
            </div>
        );
    }

    return (
        <>
            <div className="feedback-form">
                {!messageSent ? (
                    <>
                        <div className="feedback-form-body">
                            <Select
                                prefix={SKAPA_PREFIX}
                                id="feedback_category_select"
                                hintText={t("feedback.category.hint").toString()}
                                label={t("feedback.category.label").toString()}
                                className="feedback-form-category"
                                onChange={(e) => setCategory(e.target.value)}
                                data-test="feedback-type-select"
                            >
                                {Object.entries(FEEDBACK_CATEGORIES).map((j) => (
                                    <Option key={j[0]} prefix={SKAPA_PREFIX} value={j[1]} name={t(`feedback.category.` + j[0])} />
                                ))}
                            </Select>
                            <FormField prefix={SKAPA_PREFIX} className="feedback-form-message" characterLimit={FEEDBACK_MESSAGE_MAX_LENGTH}>
                                <TextArea
                                    id="ta-message"
                                    prefix={SKAPA_PREFIX}
                                    label={<>{t("feedback.message")}</>}
                                    value={message}
                                    maxLength={FEEDBACK_MESSAGE_MAX_LENGTH}
                                    onChange={(e) => setMessage(e.target.value as string)}
                                    data-test="feedback-text-input"
                                />
                            </FormField>

                            <Checkbox
                                prefix={SKAPA_PREFIX}
                                id="feedback_anonymous_check"
                                value="1"
                                label={t("feedback.email").toString()}
                                onChange={(e) => setIsAnonymous(!e.target.checked)}
                                data-test="feedback-anon-checkbox"
                            />
                        </div>
                        <div className="feedback-form-footer">
                            <Button
                                prefix={SKAPA_PREFIX}
                                type="primary"
                                inverseTheme={false}
                                className="feedback-form-submit"
                                disabled={!message.trim().length || busy}
                                onClick={() => {
                                    setBusy(true);
                                    onSubmitFeedback();
                                }}
                                data-test="feedback-submit-btn"
                            >
                                {t("feedback.submit")}
                            </Button>
                        </div>
                    </>
                ) : (
                    <div className="message-sent">
                        <SSRIcon prefix={SKAPA_PREFIX} paths={checkmarkCircle} color="#0A8A00" />
                        <h3>{t("feedback.thanks")}</h3>
                    </div>
                )}
            </div>
        </>
    );
};

export default Feedback;
