import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { loginRequest, msalConfig, graphConfig } from "./msalConfig";
import { EventType, PublicClientApplication } from "@azure/msal-browser";

const MAX_TOKEN_LIFE = 5 * 60 * 1000; // ms

export default class Auth {
    private lastToken: string | false = false;
    private lastRefresh: Date | false = false;
    private msalInstance: PublicClientApplication;
    private initialized?: boolean;

    constructor() {
        this.msalInstance = new PublicClientApplication(msalConfig);
        this.msalInstance.initialize().then(() => (this.initialized = true));

        // set active account after redirect
        this.msalInstance.addEventCallback((event: any) => {
            if (event && event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
                this.msalInstance.setActiveAccount(event.payload.account);
            }
        });
    }

    get provider() {
        return this.msalInstance;
    }

    async login(): Promise<void> {
        log("login start");

        // init still in progress?
        if (!this.initialized) {
            return this.login();
        }

        const loginResult = await this.msalInstance.handleRedirectPromise();
        if (loginResult !== null) {
            return;
        }

        const account = this.msalInstance.getActiveAccount();

        // redirect anonymous user to login page
        if (!account) {
            return this.msalInstance.loginRedirect(loginRequest);
        }
    }

    async logout(): Promise<boolean> {
        this.msalInstance.logoutRedirect();
        return true;
    }

    // Verify or inspect token at https://jwt.ms/
    async getToken(force = false): Promise<string | false> {
        const now = new Date();

        // init still in progress?
        if (!this.initialized) {
            return this.getToken(force);
        }

        if (!force && this.lastRefresh && this.lastRefresh.getTime() > now.getTime() - MAX_TOKEN_LIFE) {
            log.info("Reusing last token...");
            return this.lastToken;
        }

        const account = this.msalInstance.getActiveAccount();
        if (!account) {
            return false;
        }

        try {
            const response = await this.msalInstance.acquireTokenSilent({
                ...loginRequest,
                account,
                forceRefresh: true,
            });

            this.lastToken = response.accessToken;
            this.lastRefresh = new Date();
            return this.lastToken;
        } catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
                await this.msalInstance.acquireTokenRedirect(loginRequest).catch(log.error);
                return false;
            }

            log.error(error);
            return false;
        }
    }

    async getProfile(): Promise<null | Object> {
        // init still in progress?
        if (!this.initialized) {
            return this.getProfile();
        }

        const account = this.msalInstance.getActiveAccount();
        if (!account) {
            return null;
        }

        try {
            const response = await this.msalInstance.acquireTokenSilent({
                scopes: ["User.Read"],
                account,
            });

            const headers = new Headers();
            const bearer = `Bearer ${response.accessToken}`;

            headers.append("Authorization", bearer);

            const options = {
                method: "GET",
                headers: headers,
            };

            const [user, picture] = await Promise.all([fetch(graphConfig.graphMeEndpoint, options).then((r) => r.json()), this.getProfilePicture()]);

            return {
                ...user,
                picture,
            };
        } catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
                await this.msalInstance.acquireTokenRedirect(loginRequest).catch(log.error);
                return null;
            }

            log.error(error);
            return null;
        }
    }

    async getProfilePicture(upn?: string): Promise<string | undefined> {
        const account = this.msalInstance.getActiveAccount();
        if (!account) {
            return;
        }

        try {
            const response = await this.msalInstance.acquireTokenSilent({
                scopes: ["User.Read"],
                account,
            });

            const headers = new Headers();
            const bearer = `Bearer ${response.accessToken}`;

            headers.append("Authorization", bearer);

            const options = {
                method: "GET",
                headers: headers,
            };

            return fetch(upn ? graphConfig.graphUserPictureEndpoint(upn) : graphConfig.graphMyPictureEndpoint, options)
                .then((response) => {
                    if (!response.ok) {
                        return undefined;
                    }
                    return response.blob();
                })
                .then((blob) => blob && URL.createObjectURL(blob))
                .catch((error) => {
                    log.error(error);
                    return undefined;
                });
        } catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
                await this.msalInstance.acquireTokenRedirect(loginRequest).catch(log.error);
                return;
            }

            log.error(error);
            return;
        }
    }
}
