import { Availability, Recurrence, WorkPreference, CalendarEvent } from "@packages/common/availability";
import { CalendarView } from "@/types/calendar";
import DateTime, { CountryCode, DateTimeRange } from "@ingka-group-digital/date-time";
import { Params } from "react-router-dom";
import { Workload } from "@packages/common/workload";
import { Optional } from "@packages/common/etc";
import { Locking } from "@packages/common/locking";
import { Freezing } from "@packages/common/freezing";

/** group an array of events by a given property value, eg. date */
export const groupEvents = <K extends CalendarEvent<unknown>>(events: K[], groupKey: ((event: K, i: number) => string) | string) => {
    const groups: Record<string, K[]> = {};
    for (let i = 0; i < events.length; i++) {
        const event = events[i];
        const key = typeof groupKey !== "string" ? groupKey(event, i) : String(event[groupKey as keyof K]);
        if (!(key in groups)) {
            groups[key] = [];
        }

        groups[key].push(event);
    }

    return groups;
};

export const isAvailabilityEqual = (a: Partial<Availability>, b: Partial<Availability>) => {
    return (
        a.startDate === b.startDate &&
        a.endDate === b.endDate &&
        a.startTime === b.startTime &&
        a.endTime === b.endTime &&
        a.recurrence === b.recurrence &&
        a.status === b.status &&
        a.workPreference === b.workPreference &&
        a.weekdays?.length === b.weekdays?.length &&
        a.weekdays?.every((i) => b.weekdays?.includes(i))
    );
};

export const isLockingEqual = (a: Optional<Locking, "id">, b: Optional<Locking, "id">) => {
    return (
        a.startDate === b.startDate &&
        a.endDate === b.endDate &&
        a.startTime === b.startTime &&
        a.endTime === b.endTime &&
        a.activity.id === b.activity.id
    );
};

export const isWorkloadEqual = (a: Optional<Workload, "id">, b: Optional<Workload, "id">) => {
    if (
        a.startDate !== b.startDate ||
        a.endDate !== b.endDate ||
        a.unit?.id !== b.unit?.id ||
        a.division.id !== b.division.id ||
        a.activity.id !== b.activity.id ||
        a.weekdays.length !== b.weekdays.length ||
        !a.weekdays.every((i) => b.weekdays.includes(i)) ||
        a.requirements.length !== b.requirements.length
    ) {
        return false;
    }

    for (let i = 0; i < a.requirements.length; i++) {
        const { startTime, endTime, coworkersRequired } = a.requirements[i];
        const br = b.requirements[i];
        if (!br) {
            return false;
        }
        if (br.startTime !== startTime || br.endTime !== endTime || br.coworkersRequired !== coworkersRequired) {
            return false;
        }
    }
    return true;
};

export const compareById = (sort?: "asc" | "desc") => (a: { id?: number }, b: { id?: number }) => {
    if (a.id === undefined && b.id !== undefined) {
        return sort === "desc" ? -1 : 1;
    } else if (a.id !== undefined && b.id === undefined) {
        return sort === "desc" ? 1 : -1;
    } else if (a.id !== undefined && b.id !== undefined) {
        return sort === "desc" ? b.id - a.id : a.id - b.id;
    } else {
        return 0;
    }
};

/** create html class string from given arguments, Vue.js style */
export const generateHtmlClass = (...args: any): string => {
    return args
        .map((v: any) => {
            if (v === null || v === undefined || v === false) {
                return "";
            }

            if (typeof v === "string") {
                return v.trim();
            }

            if (v instanceof Array) {
                return generateHtmlClass(...v);
            }

            if (typeof v === "object") {
                const validClasses = Object.keys(v).filter((name) => !!v[name]);
                return validClasses.length ? generateHtmlClass(validClasses) : "";
            }

            return v.toString();
        })
        .filter((v: string) => v !== "")
        .join(" ");
};

/** generateHtmlClass, lazy version */
export const cn = (...args: any): { className: string } => {
    return { className: generateHtmlClass(args) };
};

export const compareAvailabilityPriority = (a1: Availability, a2: Availability) => {
    // vacations - highest priority
    if (a1.workPreference === WorkPreference.VACATION && a2.workPreference !== WorkPreference.VACATION) {
        return -1;
    }

    if (a1.workPreference !== WorkPreference.VACATION && a2.workPreference === WorkPreference.VACATION) {
        return 1;
    }

    // non-recurring avails
    if (a1.recurrence === Recurrence.OFF && a2.recurrence !== Recurrence.OFF) {
        return -1;
    }

    if (a1.recurrence !== Recurrence.OFF && a2.recurrence === Recurrence.OFF) {
        return 1;
    }

    // recurring un-avails
    if (a1.workPreference === WorkPreference.UNAVAIL && a2.workPreference !== WorkPreference.UNAVAIL) {
        return -1;
    }

    if (a1.workPreference !== WorkPreference.UNAVAIL && a2.workPreference === WorkPreference.UNAVAIL) {
        return 1;
    }

    return 0;
};

/** determine date range based on a given url parameters (view and date) */
export const extractDateFromParams = (
    params: Params<string> | undefined,
    allowedViews: CalendarView[],
    locale: CountryCode
): [DateTimeRange, CalendarView] => {
    const today = DateTime.today();
    const { DAY, WEEK, MONTH, YEAR, MONTH_LIST, CUSTOM, LAST_3_MONTHS, LAST_6_MONTHS, LAST_3_YEARS } = CalendarView;

    // consider first allowed view the default
    const [defaultView] = allowedViews;

    const dateStr = params ? params.date : undefined;
    const endDateStr = params ? params.endDate : undefined;
    const viewStr = params ? (params.view as CalendarView) : defaultView;

    let range: DateTimeRange;
    const view = allowedViews.includes(viewStr) ? viewStr : defaultView;

    switch (view) {
        // single day: /day/y-m-d
        case DAY: {
            let d = today;
            try {
                if (dateStr) {
                    const [year, month, day] = dateStr.split("-").map(Number);
                    d = DateTime.from(year, month, day);
                }
            } catch {
                // bad date in url, default to today
            }

            range = d.asFullRange("day");
            break;
        }

        // week range: /week/weekNo
        case WEEK: {
            const [year, week] = dateStr ? dateStr.split("-").map(Number) : [today.year, today.weekNumber(locale)];
            let d;

            try {
                d = DateTime.from(year).startOfYear().startOfWeek(locale);
                const weekNumber = d.weekNumber(locale);
                d = d.add("week", weekNumber === 1 ? week - 1 : week);
            } catch {
                d = DateTime.from(today.year).startOfYear().startOfWeek(locale);
                d = d.add("week", today.weekNumber(locale));
            }

            range = d.asFullRange("week", locale);
            break;
        }

        // month: /month/monthNum
        case MONTH:
        case MONTH_LIST: {
            let d = today;
            try {
                if (dateStr) {
                    const [year, month] = dateStr.split("-").map(Number);
                    d = DateTime.from(year, month);
                }
            } catch {
                // ignore bad date in url, default to today
            }

            range = d.asFullRange("month");
            break;
        }

        // custom period
        case CUSTOM: {
            try {
                const [startYear, startMonth, startDay] = dateStr ? dateStr.split("-").map(Number) : [today.year, today.month, today.dayOfMonth];
                const d1 = DateTime.from(startYear, startMonth, startDay);

                // if second date is not given, assume its end of d1 month
                const [endYear, endMonth, endDay] = endDateStr ? endDateStr.split("-").map(Number) : [d1.year, d1.month, d1.endOfMonth().dayOfMonth];
                const d2 = DateTime.from(endYear, endMonth, endDay);

                range = DateTime.rangeFrom(d1.startOfDay(), d2.endOfDay());
            } catch {
                // bad date in url, default to current month range
                const d = DateTime.from(today.year, today.month);
                range = d.asFullRange("month");
            }
            break;
        }

        // last n months; always start on 1st
        case LAST_3_MONTHS:
        case LAST_6_MONTHS: {
            let amt = view !== LAST_3_MONTHS ? 6 : 3;
            if (today.dayOfMonth > 1) {
                amt--;
            }

            range = DateTime.rangeFrom(today.add("month", -amt).startOfMonth().startOfDay(), today.endOfDay());
            break;
        }

        // year
        case YEAR: {
            const year = Number(dateStr);
            let d;

            try {
                d = DateTime.from(year);
            } catch {
                d = DateTime.today();
            }

            range = d.asFullRange("year");
            break;
        }

        // last 3 years
        case LAST_3_YEARS:
            range = DateTime.rangeFrom(today.add("year", -3).startOfYear().startOfDay(), today.endOfDay());
            break;

        // we should never reach this point
        default: {
            throw new Error("Invalid view");
        }
    }

    return [range, view];
};

export const deepFilter = <T>(collection: T[], searchKey: string, searchFields: string[]): T[] => {
    if (!collection?.length || searchKey.trim().length === 0) {
        return [];
    }

    const filterResults = <T>(collection: T[], searchKey: string, searchFields: string[]): T[] => {
        const res = [];
        for (const item of collection) {
            for (const field of searchFields) {
                if (deepMatch(item, searchKey, field)) {
                    res.push(item);
                    break;
                }
            }
        }
        return res;
    };

    const deepMatch = <T>(obj: T | any, searchKey: string, field: any): T => {
        const [current, ...next] = Array.isArray(field) ? field : field.split(".");
        if (next.length > 0 || (Array.isArray(next) && next?.length > 0)) {
            return deepMatch(obj[current], searchKey, next);
        }
        return iterate(obj, current, searchKey);
    };

    const iterate = <T>(obj: T | any, searchField: string, searchKey: string) => {
        if (!Array.isArray(obj) && typeof obj[searchField] !== "object" && `${obj[searchField]}`.toLowerCase().includes(searchKey.toLowerCase())) {
            return obj;
        }

        if (!Array.isArray(obj) && typeof obj[searchField] === "object") {
            iterate(obj[searchField], searchField, searchKey);
        }

        if (Array.isArray(obj)) {
            return obj.find((entry) => `${entry[searchField]}`.toLowerCase().includes(searchKey.toLowerCase()));
        }
    };

    return filterResults(collection, searchKey, searchFields);
};

export const naturalSort = <T>(list: T[], key: keyof T) => {
    const copy = [...list];
    return copy.sort((a: T, b: T) => {
        const aString = a[key] as string;
        const bString = b[key] as string;
        if (!aString || !bString) {
            return 0;
        }
        return aString.localeCompare(bString, undefined, { numeric: true });
    });
};
