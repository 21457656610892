import { useEffect } from "react";
import { Profile } from "@/types/profile";
import env from "@/env";

const { TRACKER_API_KEY, TRACKER_URL } = env;

const useAnalytics = (user: Profile | null) => {
    useEffect(() => {
        if (!user || !TRACKER_API_KEY || !TRACKER_URL) {
            return;
        }

        const script = document.createElement("script");
        script.src = TRACKER_URL + "/t.js";
        script.async = true;

        const event = new CustomEvent("start-tracking", {
            detail: {
                user,
                apiKey: TRACKER_API_KEY,
                trackerUrl: TRACKER_URL,
            },
        });

        script.onload = () => window.dispatchEvent(event);
        document.body.appendChild(script);
    }, [user]);
};

export default useAnalytics;
