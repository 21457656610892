import env from "./env";
import Logger from "./lib/logger";
import * as Sentry from "@sentry/react";

export {};

// set up logger
const logger = new Logger(env.is("local") || env.is("dev") || env.is("ppe"));
const loggerProxy = (...args: any[]) => logger.info.apply(null, args); // assume 'info', if called like log()
loggerProxy.warn = logger.warn;
loggerProxy.info = logger.info;
loggerProxy.error = logger.error;
loggerProxy.debug = logger.debug;

// @ts-ignore
globalThis.log = loggerProxy;
declare global {
    /*
    // augment window
    interface Window {
        
    }
    */

    // allow logger instance to be accessed as global
    const log: typeof loggerProxy;
}

Sentry.init({
    dsn: env.SENTRY_DSN,
    environment: env.ENV,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [env.BASE_URL ?? "localhost"],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
