export const DEFAULT_START_TIME = "08:00:00";
export const DEFAULT_END_TIME = "17:00:00";
export const MQ_MOBILE_BREAKPOINT = 800;

export const DEFAULT_FROM_TO_HOURS: [number, number] = [8, 17];

export const FEEDBACK_CATEGORIES = {
    general: "General",
    availability: "Availability",
    gap: "Availability gap",
    reports: "Reports",
    freezing: "Freezing period",
    filters: "Filters",
    workload: "Workload data",
};

export const PRIVACY_POLICY_PAGE = window.location.origin + "/" + "privacy-policy.html"; // relative to domain!
export const SKAPA_PREFIX = "mia-";

export const PRIVACY_STATEMENT_GLOBAL = "https://go.ikea.com/privacy-statement-global";
