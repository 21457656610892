import { endpoints } from "@/api/http";
import { UseQueryResult, useQuery } from "react-query";
import { QueryMap } from "@/types/query";
import { ClientError, ErrorCode } from "@/types/error";

type Options = {
    enabled?: boolean;
};

export default function useServerRequest<K extends keyof QueryMap, T extends QueryMap[K]>(
    path: K,
    args?: Parameters<T>[0],
    options?: Options
): UseQueryResult<ReturnType<T>>;
export default function useServerRequest<K extends keyof QueryMap, T extends QueryMap[K]>(path: K, options?: Options): UseQueryResult<ReturnType<T>>;
export default function useServerRequest<K extends keyof QueryMap, T extends QueryMap[K]>(
    path: K,
    optionsOrArgs: Parameters<T>[0] | Options
): UseQueryResult<ReturnType<T>> {
    const isOptions = (v: typeof optionsOrArgs): v is Options => {
        return v !== undefined && typeof v === "object" && "enabled" in v;
    };
    const args = isOptions(optionsOrArgs) ? undefined : optionsOrArgs;
    const options = isOptions(optionsOrArgs) ? optionsOrArgs : {};

    return useQuery(
        [path, args],
        async ({ queryKey }) => {
            const [, params] = queryKey;
            return endpoints.request(path, params).then(({ result }) => {
                if (result === undefined) {
                    throw new ClientError(ErrorCode.UNEXPECTED_RESPONSE);
                }

                return result as ReturnType<T>;
            });
        },
        {
            // keep previous data until request completes;
            // prevents unwanted scrolls/unecessary re-renders of components
            ...options,
            keepPreviousData: true,
        }
    );
}

/** react-query convenience wrapper function for handling multiple requests;
 *  data response should have the same type
 */
export const useServerRequests = <R extends string, K extends keyof QueryMap>(reqs: Record<R, [K, Parameters<QueryMap[K]>[0]]>) => {
    // @ts-ignore
    const result: Record<R, UseQueryResult<ReturnType<QueryMap[K]>, unknown>> = {};
    for (const r in reqs) {
        const [path, args] = reqs[r];
        // eslint-disable-next-line react-hooks/rules-of-hooks -- this hook should always be called with the same array
        result[r] = useServerRequest(path, args);
    }

    return result;
};
