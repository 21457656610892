import "./globals";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import ErrorBoundary from "./components/ErrorBoundary";

const rootNode = document.getElementById("root");
if (!rootNode) {
    throw new Error("Missing #root node!");
}

ReactDOM.createRoot(rootNode).render(
    <BrowserRouter>
        <ErrorBoundary>
            <App />
        </ErrorBoundary>
    </BrowserRouter>
);
