/*
 *  key-value storage using the LocalStorage api
 *  @link: https://developer.mozilla.org/en-US/docs/Web/API/Window/localStorage
 *
 */

import { Storage } from "./storage";

export default class LocalStorage implements Storage {
    constructor(private prefix: string = "") {}

    async get<T>(key: string): Promise<T | null>;
    async get<T>(key: string, defaultValue: T): Promise<T>;
    async get<T>(key: string, defaultValue?: T): Promise<T | null> {
        const value = window.localStorage.getItem(this.prefix + key);
        if (value === null) {
            return defaultValue ?? null;
        }

        try {
            return JSON.parse(value) as T;
        } catch (e) {
            console.error("Unexpected value in " + this.prefix + key, value);
            return defaultValue ?? null;
        }
    }

    async set(key: string, value: any): Promise<void> {
        value = JSON.stringify(value);
        window.localStorage.setItem(this.prefix + key, value);
    }

    async remove(key: string): Promise<void> {
        window.localStorage.removeItem(this.prefix + key);
    }
}
