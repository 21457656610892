import React from "react";
import "./Idle.scss";
import { useIdleTimer } from "react-idle-timer";
import Button from "@ingka/button";
import Modal, { ModalBody, ModalFooter, Prompt } from "@ingka/modal";
import { auth } from "@/api/auth";
import { SKAPA_PREFIX } from "@/lib/constants";
import useProfile from "@/hooks/useProfile";
import { useTranslation } from "react-i18next";
import env from "@/env";
import Loader from "@/components/Loaders/Loaders";

const Idle = () => {
    const profile = useProfile();
    const { t } = useTranslation();

    const timeout = env.IDLE_TIMEOUT_MS ? Number(env.IDLE_TIMEOUT_MS) : 900_000;
    const { isIdle, reset } = useIdleTimer({ timeout });

    const stayLoggedIn = () => {
        location.reload();
        reset();
    };

    if (!profile) {
        return <Loader />;
    }

    return (
        <Modal
            visible={isIdle()}
            escapable={false}
            prefix={SKAPA_PREFIX}
            handleCloseBtn={() => auth.logout()}
            className="idle-prompt"
            data-test="idle-prompt"
        >
            <Prompt
                prefix={SKAPA_PREFIX}
                header={null}
                titleId="idle-modal"
                title={t("idle.title", {
                    coworkerName: profile.name,
                })}
                footer={
                    <ModalFooter>
                        <Button prefix={SKAPA_PREFIX} onClick={() => auth.logout()} text={t("profile.logOut")} type={"secondary"} />
                        <Button prefix={SKAPA_PREFIX} text={t("idle.continue")} type={"primary"} onClick={() => stayLoggedIn()} />
                    </ModalFooter>
                }
            >
                <ModalBody>{t("idle.body")}</ModalBody>
            </Prompt>
        </Modal>
    );
};

export default Idle;
