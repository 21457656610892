import env from "@/env";
import { Configuration, LogLevel, PopupRequest } from "@azure/msal-browser";

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig: Configuration = {
    auth: {
        clientId: env.AZURE_CLIENT_ID ?? "",
        authority: env.AZURE_AUTHORITY ?? "",
        // redirectUri: environment.AZURE_REDIRECT_URI || "",
        navigateToLoginRequestUrl: true,
        //postLogoutRedirectUri: "/"
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        throw new Error(message);
                    case LogLevel.Info:
                        return log.info(message);
                    case LogLevel.Verbose:
                        return log.debug(message);
                    case LogLevel.Warning:
                        return log.warn(message);
                }
            },
        },
    },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest: PopupRequest = {
    scopes: ["api://1cea2006-5321-4bdb-a8e0-6668880349bb/profile.read"],
};

/**
 * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me/",
    graphMyPictureEndpoint: "https://graph.microsoft.com/v1.0/me/photo/$value",
    graphUserPictureEndpoint: (userPrincipleName: string) => `https://graph.microsoft.com/v1.0/users/${userPrincipleName}/photo/$value`,
};
