import React from "react";
import Modal, { ModalFooter, Prompt } from "@ingka/modal";
import Button from "@ingka/button";
import useCommonStore from "@/store/hooks/useCommonStore";
import { t } from "i18next";
import { SKAPA_PREFIX } from "@/lib/constants";

const MaintenancePopUp = () => {
    const openMaintenancePopup = useCommonStore((state) => state.openMaintenancePopup);
    const setOpenMaintenancePopup = useCommonStore((state) => state.setOpenMaintenancePopup);

    if (openMaintenancePopup !== true) {
        return <></>;
    }

    return (
        <Modal prefix={SKAPA_PREFIX} visible handleCloseBtn={() => setOpenMaintenancePopup(false)}>
            <Prompt
                title={t<string>("maintenance.title")}
                titleId="maintenance-modal"
                className="maintenance-prompt"
                header={null}
                footer={
                    <ModalFooter>
                        <Button type="primary" prefix={SKAPA_PREFIX} small onClick={() => setOpenMaintenancePopup(false)}>
                            {t<string>("action.close")}
                        </Button>
                    </ModalFooter>
                }
            >
                <div>
                    {t<string>("maintenance.popupMessage")}
                    <br></br>
                    <br></br>
                    {t<string>("tryLater")}
                </div>
            </Prompt>
        </Modal>
    );
};

export default MaintenancePopUp;
