import { useEffect, useState } from "react";
import useProfile from "./useProfile";
import { client } from "@/api/ws";

const useWebsocketEvent = (event: string) => {
    const profile = useProfile();
    const [data, setData] = useState<Record<string, any> | null>(null);

    const handler = (message: string, ...data: any[]) => {
        if (message === event) {
            setData(data);
        }
    };

    useEffect(() => {
        if (!profile) {
            return;
        }

        log.info("ws hook", event);
        client.on("message", handler);

        return () => {
            log.info("ws unhook", event);
            client.off("message", handler);
        };
    }, [profile]);

    return data;
};

export default useWebsocketEvent;
