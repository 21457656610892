import { useContext } from "react";
import { ConfigContext } from "@/context/ConfigContext";

function useConfig<T>(name: string): T | undefined;
function useConfig<T>(name: string, defaultValue: T): T;
function useConfig<T>(name: string, defaultValue?: T): T | undefined {
    const config = useContext(ConfigContext);
    return (config[name] as T) ?? defaultValue;
}

export default useConfig;
