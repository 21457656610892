/** parse %text% from first arg as prefixt */
const formatter = (type: "debug" | "info" | "warn" | "error", css: string, ...args: any) => {
    let prefix = type.toUpperCase();
    if (typeof args[0] === "string") {
        const m = args[0].match(/%([\w\s/+]+)%/);
        if (m && m[1]) {
            prefix = m[1];
            args.shift();
        }
    }

    prefix = ` %c ${prefix} `;
    return console[type](prefix, css, ...args);
};

const noop = () => "";

export default class Logger {
    constructor(private consoleOutput = false, private prefix = "%c DEBUG ") {}
    get debug() {
        if (!this.consoleOutput) {
            return noop;
        }

        return formatter.bind(window.console, "debug", "background: #ccc; color: #111");
    }
    get info() {
        if (!this.consoleOutput) {
            return noop;
        }

        return formatter.bind(window.console, "info", "background: rgba(4, 128, 232, 0.7); color: #fff");
    }
    get warn() {
        if (!this.consoleOutput) {
            return noop;
        }

        return formatter.bind(window.console, "warn", "background: rgba(186, 109, 0, 0.7); color: #fff");
    }

    get error() {
        if (!this.consoleOutput) {
            return noop;
        }

        return formatter.bind(window.console, "error", "background: rgba(186, 0, 0, 0.7); color: #fff");
    }
}
