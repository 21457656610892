import { auth } from "@/api/auth";
import { delay } from "@/lib/delay";
import useCommonStore from "@/store/hooks/useCommonStore";
import { ApiResponseError } from "@/types/error";
import { HttpClientOptions } from "@/types/httpClient";
import { Notification } from "@packages/common/notification";
import { storage } from "../storage";
import { Availability, ApprovalStatus } from "@packages/common/availability";
import { Optional } from "@packages/common/etc";
import { Locking } from "@packages/common/locking";
import { Freezing } from "@packages/common/freezing";

const logout = () => true;
const randomDelay = () => delay(Math.floor(100 + Math.random() * 3000));

/** convert nested object to url query string; note that only multi-dimensional array values are not supported */
const buildUrlParams = (rawParams: {}): string => {
    const params = new URLSearchParams();

    Object.entries(rawParams).forEach(([key, value]: [string, any]) => {
        if (Array.isArray(value)) {
            return value.forEach((value) => params.append(key, value.toString()));
        }

        if (value === null) {
            value = "";
        } else if (typeof value === "boolean") {
            value = value ? 1 : 0;
        }

        params.append(key, value);
    });

    return params.toString();
};

export class RealEndpoints extends EventTarget {
    constructor(private baseUrl = "") {
        super();
    }
    async request(uri: string, params: Record<string, any> = {}, options: HttpClientOptions = {}) {
        const token = await auth.getToken();

        const testAs = await storage.get<number>("testAs");
        if (testAs) {
            params.testAs = testAs;
        }

        // not yet authenticated (redirecting);
        if (!token) {
            throw new Error("Not logged in");
        }

        const init: HttpClientOptions = {
            method: options?.method,
            credentials: "include",
            headers: {
                Authorization: `Bearer ${token}`,
                ...options?.headers,
            },
        };
        if (options.body) {
            if (options.body instanceof FormData) {
                init.body = options.body;
            } else {
                init.body = JSON.stringify(options.body);
                if (!init.headers) {
                    init.headers = {};
                }
                init.headers["Content-Type"] = "application/json";
            }
        }

        const url = new URL(`${this.baseUrl}/${uri}`);
        url.search = buildUrlParams(params);

        // event handlers can modify the request before is sent
        this.dispatchEvent(new CustomEvent("request", { detail: { uri, init } }));

        // catch errors so we can emit an error event
        try {
            const response = await window.fetch(url as any, init);
            if (response.status === 401) {
                logout();
                //@ts-ignore
                // window.location.assign(window.location);
                throw new Error("Not Authorized");
            } else if (response.status === 503) {
                useCommonStore.getState().setOpenMaintenancePopup(true);
            }

            let body;

            if (response.headers.get("content-type")?.includes("application/json")) {
                body = await response.json();
            } else if (response.headers.get("content-type")?.includes("application/vnd.ms-excel")) {
                body = await response.blob();
            } else {
                try {
                    body = await response.text();
                    body = JSON.parse(body);
                    // eslint-disable-next-line no-empty
                } catch (err) {}
            }

            if (!response.ok) {
                throw new ApiResponseError(response.status, body || "Invalid response");
            }

            return body;
        } catch (error) {
            this.dispatchEvent(new CustomEvent("error", { detail: { error, uri, params } }));
            log.error("fetch", error);
            // re-throw error
            throw error;
        }
    }

    /**
     * Download a file
     *
     * @param url File URL
     * @param filename Optional file name, if not given file url will be used as name
     */
    async download(url: string, filename?: string): Promise<void> {
        const response = await this.request(url);
        const objectUrl = URL.createObjectURL(new Blob([response], { type: response.type }));
        const anchor = document.createElement("a");
        anchor.href = objectUrl;
        anchor.download = filename ?? url;
        anchor.click();
        URL.revokeObjectURL(objectUrl);
    }

    /**
     * Upload a file
     *
     * @param url URL for POST
     * @param key Key to use for POST (@todo: remove and use a common key for all uploads)
     * @param files List of files; currently only the first one is taken into account
     */
    async upload(url: string, key: string, files: FileList) {
        const body = new FormData();
        body.append(key, files[0]);
        return this.request(url, {}, { method: "POST", body });
    }

    async logClientError(body: {}): Promise<void> {
        return this.request("log", {}, { method: "PUT", body });
    }

    async setProfileLanguage(params?: {}): Promise<any> {
        return this.request("users/setlanguage", { ...params }, { method: "PUT" });
    }

    async setUserConsent(params?: {}): Promise<any> {
        return this.request("users/setconsent", { ...params }, { method: "PUT" });
    }

    async approveRejectAvailability(
        params: { status: ApprovalStatus; id: number; seen: boolean },
        message?: string
    ): Promise<Partial<Availability>[] | void> {
        try {
            return this.request("availability/" + (params.status === ApprovalStatus.APPROVED ? "approve" : "reject"), params, {
                method: "PUT",
                ...(message && {
                    body: { message },
                }),
            });
        } catch (err) {
            log.error("could not approve/reject availability ", err);
        }
    }

    async upsertAvailability(body: Optional<Availability, "id">[]): Promise<Availability[]> {
        return <Promise<Availability[]>>this.request("availability", {}, { method: "POST", body });
    }

    async setBaselineAvailability(body: Optional<Availability, "id">[]): Promise<Availability[]> {
        return <Promise<Availability[]>>this.request("availability/baseline", {}, { method: "POST", body });
    }

    async deleteAvailability(body: Availability): Promise<Availability[]> {
        return <Promise<Availability[]>>this.request("availability", { ...body }, { method: "DELETE" });
    }

    async createLocking(body: Optional<Locking, "id">[]): Promise<Locking[]> {
        return <Promise<Locking[]>>this.request("lockings", {}, { method: "POST", body });
    }

    async updateLocking(body: Optional<Locking, "id">[]): Promise<Locking[]> {
        return <Promise<Locking[]>>this.request("lockings", {}, { method: "PUT", body });
    }

    async deleteLocking(body: {}): Promise<Locking[]> {
        return <Promise<Locking[]>>this.request("lockings", {}, { method: "DELETE", body });
    }

    async updateNotification(params: { seen: boolean; id: number | number[] }) {
        return <Promise<Notification[]>>this.request("notifications", params, { method: "PUT" });
    }

    async markAllNotificationsAsRead() {
        return <Promise<number>>this.request("notifications/mark-all-as-read", {}, { method: "PUT" });
    }

    async saveFreezing(params: Optional<Freezing, "id" | "unit">) {
        return <Promise<Freezing[]>>this.request("freezing", {}, { method: "POST", body: params });
    }

    async removeFreezing(id: number) {
        return <Promise<Freezing[]>>this.request("freezing", { id }, { method: "DELETE" });
    }

    async updateFreezing(params: Optional<Freezing, "id" | "unit">) {
        return <Promise<Freezing[]>>this.request("freezing", {}, { method: "PUT", body: params });
    }

    async postFeedback(params: any) {
        return this.request("feedback", {}, { method: "POST", body: params });
    }

    async postUserNote(params: { coworkerId: string; note: string }) {
        return this.request("users/note", {}, { method: "PUT", body: params });
    }
}

export class FakeEndpoints {
    private modifiedAvls: Availability[] = [];

    constructor(private baseUrl = "") {}

    async request(uri: string, params: {} = {}, options: HttpClientOptions = {}) {
        const init: HttpClientOptions = {
            method: options?.method,
            credentials: "include",
            headers: {
                ...options?.headers,
            },
        };
        if (options && options.body) {
            init.body = JSON.stringify(options.body);
        }
        await randomDelay();
        uri = uri.replaceAll("/", ".");
        const r = await window.fetch(`${this.baseUrl}/fake/${uri}.json`, init);

        const data = await r.json();
        return data;
    }

    async download(url: string): Promise<void> {
        throw new ApiResponseError(500, "Not implemented");
    }

    async upload(url: string, key: string, files: FileList) {
        throw new ApiResponseError(500, "Not implemented");
    }

    async logClientError(params: {}): Promise<void> {
        return; // no logging for fake endpoints
    }

    async setProfileLanguage(params?: {}) {
        return params;
    }

    async setUserConsent(params?: {}) {
        return params;
    }

    async approveRejectAvailability(params: { status: ApprovalStatus; id: number }): Promise<Partial<Availability>[] | void> {
        return [];
    }

    async upsertAvailability(body: Optional<Availability, "id">[]): Promise<Availability[]> {
        return [];
    }

    async setBaselineAvailability(body: Optional<Availability, "id">[]): Promise<Availability[]> {
        return [];
    }

    async deleteAvailability(body: Availability): Promise<Availability[]> {
        return [];
    }

    async createLocking(body: Optional<Locking, "id">[]): Promise<Locking[]> {
        return <Locking[]>body;
    }

    async updateLocking(body: Optional<Locking, "id">[]): Promise<Locking[]> {
        return <Locking[]>body;
    }

    async deleteLocking(body: {}): Promise<Locking[]> {
        return [{ id: body } as Locking];
    }

    async updateNotification(params: { seen: boolean; id: number | number[] }) {
        return "ok";
    }

    async markAllNotificationsAsRead() {
        return "ok";
    }

    async saveFreezing(params: Optional<Freezing, "id" | "unit">): Promise<Freezing[]> {
        log("save freezing fake", params);
        return [];
    }

    async removeFreezing(id: number): Promise<Freezing[]> {
        log("remove freezing fake end point", id);
        return [];
    }

    async updateFreezing(params: Optional<Freezing, "id" | "unit">): Promise<Freezing[]> {
        log("update freezing fake end point", params);
        return [];
    }

    async postFeedback(params: any) {
        log("post feedback fake endpoint", params);
        return [];
    }
    async postUserNote(params: { coworkerId: string; note: string }) {
        log("post user note fake endpoint", params);
        return [];
    }
}
