import { io, Socket } from "socket.io-client";
import { auth } from "../auth";
import WsClient from "./WsClient";
import { storage } from "../storage";

type Options = {
    url: string;
};

export default class SocketIo implements WsClient {
    private client?: Socket;

    constructor(private options: Options) {}

    private async getClient() {
        if (this.client) {
            return this.client;
        }

        let query = undefined;
        const testAs = await storage.get<number>("testAs");
        if (testAs) {
            query = { testAs };
        }

        const { url } = this.options;
        const token = await auth.getToken();
        this.client = io(url, {
            transports: ["websocket"],
            auth: {
                token,
            },
            query,
        });

        this.client.on("connect", () => log.info("ws: connected"));
        this.client.on("connect_error", (error) => log.error(`ws: ${error}`, error));
        this.client.on("disconnect", () => log.info(`ws: disconnected`));

        return this.client;
    }

    on(event: string, handler: (...args: any) => void) {
        this.getClient().then((client) => client.on(event, handler));
    }

    off(event: string, handler: (...args: any) => void) {
        if (this.client) {
            this.client.off(event, handler);
        }
    }
}
