import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Loader from "@/components/Loaders/Loaders";
import { SKAPA_PREFIX } from "@/lib/constants";
import useProfile from "@/hooks/useProfile";
import Modal, { ModalBody, ModalFooter, ModalHeader, Prompt } from "@ingka/modal";
import Button from "@ingka/button";
import { endpoints } from "@/api/http";
import { auth } from "@/api/auth";
import "./Consent.scss";

type Props = {
    onClose: () => void;
};

const WithdrawConsent = ({ onClose }: Props) => {
    const profile = useProfile();
    const { t } = useTranslation();

    const onWithdraw = async () => {
        await endpoints.setUserConsent({
            gdprConsent: false,
        });
        auth.logout();
    };

    if (!profile) {
        return <Loader />;
    }

    return (
        <Modal
            className="withdraw-consent-popup"
            visible
            escapable
            handleCloseBtn={onClose}
            prefix={SKAPA_PREFIX}
            data-test="withdraw-consent-prompt"
        >
            <Prompt
                prefix={SKAPA_PREFIX}
                title={t("consent.withdrawConsentTitle")}
                titleId="withdraw-consent-modal"
                className="withdraw-consent-prompt"
                header={<ModalHeader />}
                footer={
                    <ModalFooter>
                        <Button prefix={SKAPA_PREFIX} text={t("action.cancel")} type={"secondary"} onClick={onClose} />
                        <Button prefix={SKAPA_PREFIX} text={t("idle.continue")} type={"primary"} onClick={onWithdraw} />
                    </ModalFooter>
                }
            >
                <ModalBody>
                    <p>{t("consent.withdrawConsentBody")}</p>
                </ModalBody>
            </Prompt>
        </Modal>
    );
};

export default WithdrawConsent;
