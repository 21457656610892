export enum CalendarView {
    DAY = "day",
    WEEK = "week",
    MONTH = "month",
    MONTH_LIST = "month-list",
    LAST_3_MONTHS = "last-3-months",
    LAST_6_MONTHS = "last-6-months",
    YEAR = "year",
    LAST_3_YEARS = "last-3-years",
    CUSTOM = "period",
}
