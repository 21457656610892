import React, { useEffect, useState } from "react";
import SSRIcon from "@ingka/ssr-icon";
import personIcon from "@ingka/ssr-icon/paths/person";
import { SKAPA_PREFIX } from "@/lib/constants";
import "./ProfilePicture.scss";
import { useQuery } from "react-query";
import { auth } from "@/api/auth";

const ProfilePicture = (props: { mail: string; size?: number }) => {
    const { mail, size = 40 } = props;
    const [pictureUrl, setPictureUrl] = useState<string | undefined>(undefined);
    useEffect(() => {
        auth.getProfilePicture(mail).then((url) => {
            setPictureUrl(url);
        });
    }, []);

    return (
        <div className="profile-picture" style={{ width: `${size}px`, height: `${size}px` }}>
            {pictureUrl ? (
                <img src={pictureUrl} width={size} height={size} className="img" />
            ) : (
                <div className="initials" style={{ width: `${size}px`, height: `${size}px` }}>
                    <SSRIcon paths={personIcon} color={"#000000"} prefix={SKAPA_PREFIX} />
                </div>
            )}
        </div>
    );
};

export default ProfilePicture;
