import { useLocation, useSearchParams } from "react-router-dom";

export const useApplicationUrl = (root: "coworker" | "dashboard" | "switch") => {
    const { pathname } = useLocation();
    const [searchParams] = useSearchParams();

    const s = searchParams ? `?${searchParams}` : "";

    if (root !== "switch") {
        return "/" + root + s;
    }

    if (pathname.includes("/coworker")) {
        return "/dashboard" + s;
    }

    return "/coworker" + s;
};
