import { endpoints } from "@/api/http";
import React, { Component, ErrorInfo, ReactNode } from "react";
import ErrorPage from "./ErrorPage/ErrorPage";
import { NotAuthorizedError, AuthError } from "@/types/error";

interface Props {
    children: ReactNode;
}

interface State {
    hasError: boolean;
    status: number;
    userMessage: string;
}

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false,
        status: 500,
        userMessage: "Application Error",
    };

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // error during auth; we cannot log this to server
        if (error instanceof AuthError) {
            this.setState({
                hasError: true,
                status: 401,
                userMessage: "We could not authenticate you",
            });

            // specific error after auth: user has not access; cannot log this to server either
        } else if (error instanceof NotAuthorizedError) {
            this.setState({
                hasError: true,
                status: 403,
                userMessage: "Access restricted environment",
            });

            // any other error, after user is logged in
        } else {
            log.error("-> render failed because of error within component", error);

            const data = {
                message: error.message,
                uri: window.location.href,
                componentStack: errorInfo.componentStack,
            };

            endpoints.logClientError({ data });
        }
    }

    render() {
        const { hasError, status, userMessage } = this.state;
        if (!hasError) {
            return this.props.children;
        }

        return <ErrorPage title={status.toString()} description={userMessage} />;
    }
}

export default ErrorBoundary;
