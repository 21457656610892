import { useEffect, useState } from "react";

const useMediaQuery = (condition: (w: number) => boolean) => {
    const [value, setValue] = useState(condition(window.innerWidth));

    const updateMedia = () => {
        setValue(condition(window.innerWidth));
    };

    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    }, []);

    return value;
};

export default useMediaQuery;
