export default class Auth {
    async login() {
        // do nothing
    }

    async logout() {
        // do nothing
    }

    async getToken(_force = false): Promise<string | false> {
        return "fake-token";
    }

    async getProfile(): Promise<any> {
        return {
            givenName: "e2e",
            surname: "tester",
            displayName: "e2e tester",
        };
    }

    async getProfilePicture(): Promise<string | undefined> {
        return;
    }
}
