import React, { FormEvent, useState } from "react";
import { endpoints } from "@/api/http";
import i18next from "i18next";
import { SKAPA_PREFIX } from "@/lib/constants";
import { ClientError, ErrorMessage } from "@/types/error";
import { useTranslation } from "react-i18next";
import CookieJar from "@/lib/cookieJar";
import useProfile from "@/hooks/useProfile";
import ListView, { ListViewItem } from "@ingka/list-view";
import { TRANSLATION_LANGUAGES } from "@packages/common/etc";
import { useModals } from "./Modals/ModalStack";
import DateTime from "@ingka-group-digital/date-time";

const oppJar = new CookieJar({ name: "opp" });

const LanguageSelect = () => {
    const profile = useProfile();
    const { setPrompt, closeModal } = useModals();
    const [language, setLanguage] = useState(
        oppJar.get("lang", profile?.language) in TRANSLATION_LANGUAGES ? oppJar.get("lang", profile?.language) : "en-US"
    );
    const { t } = useTranslation();

    const onChangeHandler = async (language: string) => {
        const i18lng = language === "en-US" ? "en" : language;
        try {
            oppJar.set("lang", language);
            await Promise.all([i18next.changeLanguage(i18lng), endpoints.setProfileLanguage({ language })]);
            setLanguage(language);
            profile!.language = language;
            closeModal();
            DateTime.config({ locale: i18lng });
        } catch (e) {
            // most likely server validation error, language not in the allowed list
            setPrompt({
                title: t("genericErrorTitle"),
                error: e instanceof ErrorMessage ? e : new ClientError(),
            });
        }
    };

    return (
        <div className="language-select">
            <h2>{t("preferredLanguageDescription")}</h2>
            <h4>{t("availableLanguages")}</h4>
            <ListView prefix={SKAPA_PREFIX} id="language-select" size="small" showDivider={false} title="">
                {Object.entries(TRANSLATION_LANGUAGES).map(([value, label]) => (
                    <ListViewItem
                        id={`select-language-${value}`}
                        prefix={SKAPA_PREFIX}
                        key={value}
                        title={label}
                        control="radiobutton"
                        controlProps={{ checked: language === value, value }}
                        onChange={(e) => onChangeHandler((e as FormEvent<HTMLInputElement>).currentTarget.value)}
                    />
                ))}
            </ListView>
        </div>
    );
};

export default LanguageSelect;
