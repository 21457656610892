import React from "react";
import "./ErrorPage.scss";
import errorVideo from "../../assets/video/error-video.mp4";
import { useTranslation } from "react-i18next";

type Props = {
    title: string;
    description: string;
    data?: any;
};

export default function ErrorPage(props: Props) {
    const { t } = useTranslation();
    const { title, description, data } = props;

    const encodedData = data instanceof Error ? btoa(JSON.stringify(data.message, null, 2)) : null;

    return (
        <div className="error-landing-page">
            <div className="container">
                <video className="error-video" loop autoPlay muted playsInline>
                    <source src={errorVideo} />
                </video>
                <div className="title">
                    <h1>{t("errorPage.title") + ": " + title}</h1>
                </div>
                <div className="instructions">
                    <p>{t(description)}</p>
                    <p>{t("errorPage.tryAgain")}</p>
                    <div className="link-text">
                        <p>
                            {t<string>("errorPage.help")}
                            <a href="https://now.ingka.com/" target="_blank" rel="noreferrer">
                                {t("errorPage.link")}
                            </a>
                        </p>
                        {encodedData && (
                            <div className="debug-info">
                                <p>
                                    <b>
                                        To help us identify the cause, please click on the text below to copy it, the paste it in your support ticket:
                                    </b>
                                </p>
                                <p>
                                    <code onClick={() => navigator.clipboard && navigator.clipboard.writeText(encodedData)}>{encodedData}</code>
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
