import React, { useState, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";

import { endpoints } from "@/api/http";
import { auth } from "@/api/auth";

import useProfile from "@/hooks/useProfile";
import useConfig from "@/hooks/useConfig";

import { SKAPA_PREFIX } from "@/lib/constants";
import { PRIVACY_STATEMENT_GLOBAL } from "@/lib/constants";

import Modal, { ModalBody, ModalFooter, Prompt } from "@ingka/modal";
import Checkbox from "@ingka/checkbox";
import FormField from "@ingka/form-field";
import Button from "@ingka/button";

import { getPrivacyPolicy } from "../Profile/Profile";

import "./Consent.scss";

const NoConsent = () => {
    const showConsent = useConfig<boolean>("isdpConsent", false);
    const profile = useProfile();
    const { t } = useTranslation();
    const [consent, setConsent] = useState<boolean | null>(null);
    const [visible, setVisible] = useState<boolean>(false);

    useEffect(() => {
        setVisible(showConsent);
    }, [showConsent]);

    const handleOnClick = async () => {
        if (consent === null) {
            return;
        }

        await endpoints.setUserConsent({
            gdprConsent: +consent,
        });
        setVisible(false);
        if (!consent) {
            auth.logout();
        }
    };

    return (
        <Modal
            className="no-consent-popup"
            visible={visible}
            escapable={false}
            prefix={SKAPA_PREFIX}
            handleCloseBtn={() => {
                return;
            }}
            data-test="no-consent-prompt"
        >
            <Prompt
                prefix={SKAPA_PREFIX}
                title={t("consent.title")}
                titleId="no-consent-modal"
                header={null}
                className="no-consent-prompt"
                footer={
                    <ModalFooter>
                        <Button
                            prefix={SKAPA_PREFIX}
                            text={t("consent.save")}
                            type={"primary"}
                            disabled={consent === null}
                            onClick={handleOnClick}
                            data-test="consent-save"
                        />
                    </ModalFooter>
                }
            >
                <ModalBody>
                    <div className="consent-body">
                        {t<string>("consent.bodyText")}
                        &nbsp;
                        <a className="consent-modal-privacy-statement-link" target="_blank" href={PRIVACY_STATEMENT_GLOBAL} rel="noreferrer">
                            {t("consent.privacyStatementLinkText")}
                        </a>
                    </div>
                    <FormField prefix={SKAPA_PREFIX} className="checkbox-container">
                        <Checkbox
                            prefix={SKAPA_PREFIX}
                            id=""
                            label={
                                <div className="checkbox-container--first-checkbox--wrapper">
                                    {t<string>("consent.consentFirst")}
                                    &nbsp;
                                    <a
                                        className="consent-modal-privacy-statement-link"
                                        target="_blank"
                                        href={getPrivacyPolicy(profile!)}
                                        rel="noreferrer"
                                    >
                                        {t("consent.consentLink")}
                                    </a>
                                    &nbsp;
                                    {t<string>("consent.consentSecond")}
                                </div>
                            }
                            checked={consent === true}
                            value=""
                            onChange={(e) => (e.target.checked ? setConsent(e.target.checked) : setConsent(null))}
                            data-test="consent-checkbox-1"
                        />
                        <Checkbox
                            prefix={SKAPA_PREFIX}
                            id=""
                            label={
                                <Trans i18nKey="noConsent">
                                    <span>
                                        No, I do not consent. <strong>By ticking this box you can't use People Availability</strong>. For further
                                        information please contact
                                    </span>
                                    &nbsp;
                                    <a
                                        className="consent-modal-privacy-statement-link"
                                        target="_blank"
                                        href="mailto:digital.cisdp.cn@ingka.com"
                                        rel="noreferrer"
                                    >
                                        {t("consent.noConsentLink")}
                                    </a>
                                </Trans>
                            }
                            checked={consent === false}
                            value=""
                            onChange={(e) => (e.target.checked ? setConsent(!e.target.checked) : setConsent(null))}
                            data-test="consent-checkbox-3"
                        />
                    </FormField>
                </ModalBody>
            </Prompt>
        </Modal>
    );
};

export default NoConsent;
