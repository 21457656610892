import React from "react";
import useConfig from "@/hooks/useConfig";
import useProfile from "@/hooks/useProfile";
import useSurveyPrompt from "@/hooks/useSurveyPrompt";
import { SKAPA_PREFIX } from "@/lib/constants";
import Button from "@ingka/button";
import Modal, { ModalFooter, ModalHeader, Prompt, Theatre } from "@ingka/modal";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type SurveyConfig = {
    lang: Record<string, string>;
    date: string;
};

const SurveyPopup = () => {
    const surveyEnabled = useConfig<boolean>("surveyEnabled", false);
    const [showSurvey, setShowSurvey] = useState(surveyEnabled);

    const { t } = useTranslation();
    const profile = useProfile();
    const surveyConfig = useConfig<SurveyConfig>("survey");

    const userLanguage = profile?.language || "en-US";
    const surveyId = surveyConfig?.lang[userLanguage] || surveyConfig?.lang["en-US"] || null;
    const surveyDate = surveyConfig?.date ?? null;

    const sevenDaysMs = 7 * 24 * 60 * 60 * 1000;
    const { showPrompt, dismissTemporarily, ignorePermanently } = useSurveyPrompt(surveyDate, surveyId, sevenDaysMs);

    if (!profile || !surveyDate || !surveyId || !surveyEnabled) {
        return;
    }

    return (
        <>
            {showPrompt && (
                <Modal prefix={SKAPA_PREFIX} visible handleCloseBtn={dismissTemporarily}>
                    <Prompt
                        prefix={SKAPA_PREFIX}
                        title={t("survey.title")}
                        titleId="survery-modal"
                        header={<ModalHeader ariaCloseTxt="Close prompt" />}
                        footer={
                            <ModalFooter>
                                <Button text={t("action.ok")} type="primary" small onClick={() => setShowSurvey(true)} data-analytics="survey-ok" />
                                <Button text={t("action.noThanks")} type="secondary" small onClick={ignorePermanently} data-analytics="survey-no" />
                            </ModalFooter>
                        }
                    >
                        <p>{t("survey.body")}</p>
                        <p>{t("survey.disclaimer")}</p>
                    </Prompt>
                </Modal>
            )}
            {showSurvey && (
                <Modal prefix={SKAPA_PREFIX} visible={showPrompt} handleCloseBtn={ignorePermanently}>
                    <Theatre prefix={SKAPA_PREFIX} header={<ModalHeader title={t("survey.title")} />}>
                        <iframe
                            width="100%"
                            height="90%"
                            src={`https://forms.office.com/Pages/ResponsePage.aspx?id=${surveyId}&embed=true`}
                            style={{ border: "none", maxWidth: "100%", maxHeight: "100vh" }}
                        />
                    </Theatre>
                </Modal>
            )}
        </>
    );
};

export default SurveyPopup;
