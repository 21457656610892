class Env {
    ENV = process.env.VITE_ENV;
    BASE_URL = process.env.VITE_BASE_URL;
    AZURE_CLIENT_ID = process.env.VITE_AZURE_CLIENT_ID;
    AZURE_REDIRECT_URI = process.env.VITE_AZURE_REDIRECT_URI;
    AZURE_AUTHORITY = process.env.VITE_AZURE_AUTHORITY;
    TRACKER_URL = process.env.VITE_TRACKER_URL;
    TRACKER_API_KEY = process.env.VITE_TRACKER_API_KEY;
    FAKE_AUTH = process.env.VITE_FAKE_AUTH;
    IDLE_TIMEOUT_MS = process.env.VITE_IDLE_TIMEOUT_MS;
    SENTRY_DSN = process.env.VITE_SENTRY_DSN;

    toString() {
        return this.ENV;
    }

    is(env: "ppe" | "dev" | "demo" | "local" | "prod"): boolean {
        return this.ENV === env;
    }

    isFake() {
        return this.is("local") && new URLSearchParams(window.location.search).has("fake");
    }
}

const env = new Env();

export default Object.freeze(env);
