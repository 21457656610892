import { Weekday } from "@packages/common/calendar";
import { Availability, CalendarEvent } from "@packages/common/availability";
import { Locking } from "@packages/common/locking";
import DateTime from "@ingka-group-digital/date-time";
import { t } from "i18next";

export const toQueryString = (obj: any) => new URLSearchParams(obj).toString();

export const randomValue = (arr: any[]) => arr[Math.floor(Math.random() * arr.length)];

export const weekdayListFormat = (input: Weekday[], format: "short" | "long", locale = "default") => {
    const weekdayNames = DateTime.weekdayNames(format, locale);
    const intlLocale = locale !== "default" ? locale : DateTime.getDefaultLocale();
    const list = input.map((n) => weekdayNames[n - 1]);

    // use system-configured list format if possible
    if ("ListFormat" in Intl) {
        return new Intl.ListFormat(intlLocale).format(list);
    }

    // fallback to comma separated weekdays
    return list.join(", ");
};

export const convertToTitleCase = (str: string) => {
    return str
        .split(" ")
        .map((w) => w.charAt(0).toUpperCase() + w.substring(1).toLowerCase())
        .join(" ");
};

export const capitalize = (str: string) => {
    return str.charAt(0).toLocaleUpperCase() + str.substring(1);
};

export const generateRandomKey = () => {
    return Math.random().toString(36).slice(2, 7);
};

export const generateRandomNumber = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

/** translated full description of a calendar event; for use within title attributes */
export const getEventDescription = (event: CalendarEvent<Availability | Locking>): string => {
    const { timeRange, eventProps } = event;
    const { startDate, endDate, recurrence, weekdays } = eventProps as Availability;
    const [startTime, endTime] = timeRange.exportFormatted("HH:MM");

    // recurring event
    if (recurrence > 0) {
        const weekdayStr = weekdays.map((w: number) => DateTime.weekdayNames()[w - 1]).join("/");

        if (timeRange.timeExtendsAllDay()) {
            return t("eventDescription.recurringAllDay", { startDate, endDate, weekdays: weekdayStr });
        }

        return t("eventDescription.recurring", {
            startDate,
            endDate,
            weekdays,
            startTime,
            endTime,
        });
    }

    // one time event
    if (timeRange.timeExtendsAllDay()) {
        return t("eventDescription.singleAllDay", { date: startDate, startTime, endTime });
    }

    return t("eventDescription.single", { startDate, endDate, startTime, endTime });
};

/**
 * Get distance between 2 strings (very basic and slow implementation)
 * Comparison is case-sensitive!
 *
 * @link https://gist.github.com/andrei-m/982927
 * @param a first string
 * @param b second string
 * @returns number (0 = exact match)
 */
export const levenshtein = (a: string, b: string): number => {
    if (a.length === 0) {
        return b.length;
    }

    if (b.length === 0) {
        return a.length;
    }

    if (a.length > b.length) {
        const tmp = a;
        a = b;
        b = tmp;
    }

    const alen = a.length;
    const blen = b.length;
    const row = [...Array(alen).keys()];

    let m = Number.MAX_SAFE_INTEGER;
    for (let i = 1; i <= blen; i++) {
        m = i;
        let j;
        for (j = 1; j <= alen; j++) {
            const t = row[j - 1];
            row[j - 1] = m;
            m = b[i - 1] === a[j - 1] ? t : Math.min(t + 1, Math.min(m + 1, row[j] + 1));
        }
        row[j - 1] = m;
    }

    return m;
};
