import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useConfig from "@/hooks/useConfig";
import { PRIVACY_STATEMENT_GLOBAL, SKAPA_PREFIX } from "@/lib/constants";
import ListView, { ListViewItem } from "@ingka/list-view";
import WithdrawConsent from "./WithdrawConsent";
import HaveConsent from "./HaveConsent";

const PrivacyPolicySelect = () => {
    const shouldShowConsent = useConfig<boolean>("isdpConsent", false);
    const { t } = useTranslation();
    const [showHaveConsentPopup, setShowHaveConsentPopup] = useState<boolean>(false);
    const [showWithdrawPopup, setWithdrawPopup] = useState<boolean>(false);

    const handleOnWithdraw = () => {
        setShowHaveConsentPopup(false);
        setWithdrawPopup(true);
    };

    return (
        <div className="privacy-policy-select">
            {showHaveConsentPopup && <HaveConsent onWithdraw={handleOnWithdraw} onClose={() => setShowHaveConsentPopup(false)} />}
            {showWithdrawPopup && <WithdrawConsent onClose={() => setWithdrawPopup(false)} />}
            <ListView prefix={SKAPA_PREFIX} id={"privacy-policy-listview"}>
                <ListViewItem
                    inset
                    emphasised
                    href={PRIVACY_STATEMENT_GLOBAL}
                    newWindow="_blank"
                    prefix={SKAPA_PREFIX}
                    title={t("consent.privacyStatementTitle")}
                    control="link"
                    controlIcon="linkOut"
                />
                {shouldShowConsent ? (
                    <ListViewItem
                        inset
                        emphasised
                        prefix={SKAPA_PREFIX}
                        title={t("consent.consentToUseData")}
                        control="navigational"
                        controlIcon="chevronRight"
                        onClick={() => setShowHaveConsentPopup(true)}
                    />
                ) : (
                    <></>
                )}
            </ListView>
        </div>
    );
};

export default PrivacyPolicySelect;
